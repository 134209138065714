// Home.js
import { Link } from 'react-router-dom';
import Icons from '../../../Constants/Icon';
import React, { useState, useEffect } from 'react';
import Language from './LanguageSelector';
import Customdrop from './customdrop';
import { NavLink } from 'react-router-dom';
import BookNow from '../../../component/Button/BookNow';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Header({ setLoading, setServiced }) {
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isOpen, setIsOpen] = useState(false);


    const languages = [
        { code: 'en', name: 'English' },
        { code: 'fr', name: 'Français' },
    ];

    const experience = [
        { name: 'resort activities', Route: "./resortactivities" },
        { name: 'resort services', Route: '/resortservices', id: 'resort-services' },
        { name: 'Local activities & Dining', Route: "./restaurants" },
    ];
    const groupsdata = [
        { name: 'family & friends', Route: '/family&friends' },
        { name: 'corporate groups', Route: "./corporate " },

        { name: 'Weddings', Route: "./weddings" },
    ]
    const handleItemselect = (index, service) => {
        const selectedExperience = experience[index];
        console.log("Selected experience:", selectedExperience);
        console.log("Selected service:", service);

        if (selectedExperience && selectedExperience.id === 'resort-services') {
            window.location.href = `${selectedExperience.Route}/${service}`;
        } else {
            console.error("Invalid selection or missing ID property.");
            setServiced("yourServiceValue");
            setTimeout(() => {
                setServiced('');
            }, 1000);
        }
    };
    const displayText = [{
        route: "",
        displayText: "the experience"
    }]
    const groups = [{
        route: "",
        displayText: "Groups & Events"
    }]
    const [currentLanguage, setCurrentLanguage] = useState('en');
    const [activeIndex, setActiveIndex] = useState(0);
    const [menuHeader, setMenuHeader] = useState(true);

    const openmenu = () => {
        setMenuHeader(prevState => !prevState);
    }
    const handleLinkClick = () => {
        const mediaQuery = window.matchMedia('(max-width: 1280px)');
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
        if (mediaQuery.matches) {
            setMenuHeader(false);
        } else {
            setMenuHeader(true);
        }
    };
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1280px)');

        const handleMediaQueryChange = (e) => {
            if (e.matches) {
                setMenuHeader(false);
            } else {
                setMenuHeader(true);
            }
        };
        handleMediaQueryChange(mediaQuery);
        mediaQuery.addListener(handleMediaQueryChange);
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);
    const handleItemClick = (index) => {
        setActiveIndex(index);
    };

    const items = [
        { text: 'Our Chalets', link: '/discover_chalets', typeof: "chalets" },
        { text: 'the experience', link: '/experience', typeof: "experience" },
        { text: 'Groups & Events', link: '/groups', typeof: "groups" },
        { text: 'Contact Us', link: '/contactus', typeof: "chalets" },
    ];

    const handleLanguageChange = (newLanguage, languageCode) => {
        setCurrentLanguage(newLanguage);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 800);

    };
  
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1280px)');
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            const shouldShowHeader = currentScrollY === lastScrollY || currentScrollY <= 100;
            setIsHeaderVisible(shouldShowHeader);
            setLastScrollY(currentScrollY);
            setIsOpen(false)
            if (mediaQuery.matches) {
                setMenuHeader(false);
            } else {
                setMenuHeader(true);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const urls = {
        en: "https://company-15146508.staycation.igms.com/?lang=en",
        fr: "https://company-15146508.staycation.igms.com/?lang=fr"
    };
    const currentUrl = urls[currentLanguageCode] || urls.en;

    return (
        <div className={`bg-treecolor min_header ${isHeaderVisible ? "header_i" : "header_p"}`} >

            <header>
                <nav class="bg-treecolor border-gray-200    py-2.5 md:pb-7 pt-7 max-[767px]:pt-3 dark:bg-gray-800 AkagiPro-Black ">
                    <div class="flex flex-wrap justify-between items-center mx-auto container relative">
                        <div href="https://flowbite.com" class="flex items-center -mt-4 max-[767px]:-mt-2 ">
                            <Link to='./'> <LazyLoadImage src={Icons.Group} class="mr-3  max-[767px]:mr-0 w-72 max-[767px]:w-24" alt="Flowbite Logo" /></Link>

                        </div>
                        {menuHeader &&
                            <div onClick={openmenu} className='hidden max-[1280px]:block fixed left-0 top-0 w-full h-[100vh] bg-transparent'></div>}
                        <div class="flex items-center xl:order-2">
                            <button onClick={openmenu} data-collapse-toggle="mobile-menu-2 " type="button" class="max-[767px]:mr-2 text-white menu_button inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg xl:hidden  dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                                <LazyLoadImage src={Icons.menu} className='scale-150	max-[767px]:scale-100' />
                            </button>
                            <div href="#" class=" font-medium rounded-xl text-sm  xl:px-2 py-2 xl:py-2.5 mr-2 button_books "> {BookNow.map((BookNow) => (<Link to={currentUrl}> <button onClick={handleLinkClick} className=' uppercase bg-lightyellow text-white  w-40 max-[767px]:bg-sky-768 max-[767px]:w-16 book_button md:mx-2 book_now AkagiPro-Black text-20' >{t("book now").slice(0, 8)}</button></Link>))}</div>
                            <div href="#" class="text-white bg-primary-700 text-20 languagaes_fr AkagiPro-Black"><div className=" " style={{ maxWidth: "106px" }}>
                                <Language
                                    languages={languages}
                                    defaultLanguage={currentLanguage}
                                    onLanguageChange={handleLanguageChange}
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                /> </div></div>

                        </div>
                        {menuHeader &&
                            < div class="  menu_h justify-between items-center w-full xl:flex xl:w-auto xl:order-1" id="mobile-menu-2">
                                <div className='fixed top-0 left-0 w-full h-full xl:hidden' onClick={openmenu} style={{ zIndex: "999" }}></div>
                                <ul style={{ zIndex: "9999" }} class="header_menu md:gap-2 flex max-[1280px]:absolute z-40 max-[1280px]:bg-white  max-[1280px]:pl-1 max-[1280px]:pr-9 mt_0  max-[1280px]:text-black flex-col max-[1280px]:mt-0 font-medium xl:flex-row xl:space-x-8 text-white AkagiPro-Black capitalize p-0">
                                    {items.map((item, index) => (
                                        <div className='menu_mo'>
                                            {item.typeof == "chalets" && (
                                                <li key={index}

                                                    onClick={() => handleItemClick(index)}>
                                                    <div class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 xl:hover:bg-transparent xl:border-0 xl:hover:text-primary-700 xl:p-0 dark:text-gray-400 xl:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white xl:dark:hover:bg-transparent dark:border-gray-700">
                                                        <NavLink activeClassName="active" className=' max-[1280px]:text-black  AkagiPro-Black text-20 uppercase' onClick={handleLinkClick} to={item.link} >{t(item.text)}</NavLink>
                                                    </div>
                                                </li>)}
                                            {item.typeof == "experience" && (
                                                <li

                                                    onClick={() => handleItemClick(index)}>
                                                    <div class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 xl:hover:bg-transparent xl:border-0 xl:hover:text-primary-700 xl:p-0 dark:text-gray-400 xl:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white xl:dark:hover:bg-transparent dark:border-gray-700">
                                                        <NavLink activeClassName="active" className=' max-[1280px]:text-black experience_data  AkagiPro-Black text-20 uppercase' to={item.link} >
                                                            <Customdrop
                                                                languages={experience}
                                                                displayText={displayText}
                                                                handleItemselect={(index) => handleItemselect(index)}

                                                            /></NavLink>
                                                    </div>
                                                </li>)}
                                            {item.typeof == "groups" && (
                                                <li

                                                    onClick={() => handleItemClick(index)}>
                                                    <div class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 xl:hover:bg-transparent xl:border-0 xl:hover:text-primary-700 xl:p-0 dark:text-gray-400 xl:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white xl:dark:hover:bg-transparent dark:border-gray-700">
                                                        <NavLink activeClassName="active" className=' max-[1280px]:text-black experience_data AkagiPro-Black text-20 uppercase' to={item.link} >
                                                            <Customdrop
                                                                languages={groupsdata}
                                                                displayText={groups}
                                                                handleItemselect={(index) => handleItemselect(index)}

                                                            /></NavLink>
                                                    </div>
                                                </li>)}
                                        </div>
                                    ))}

                                </ul>
                            </div>
                        }
                    </div>
                </nav>
            </header>

        </div >
    );
}

export default Header;
