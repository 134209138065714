// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationFR from './fr.json';


const resources = {
    fr: { translation: translationFR },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en', // Default language
        interpolation: {
            escapeValue: false // Not needed for React
        }
    });

export default i18n;
