import React from "react";
import Icons from "../../Constants/Icon";
import fiddlerchalet from '../../Assets/Icons/texture-chalet.png'
import { Link } from "react-router-dom";
import BookNow from "../Button/BookNow";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
function ContactGroup({ Corporategroupsd }) {
    const sectionimg = {
        backgroundImage: 'url(' + fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minheight: "882px",

    };
    const handleLinkClick = () => {

        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    };
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div>
            <div className="bg-lightsky md:py-16 md:pt-14 max-[767px]:py-5 md:mb-5 md:mt-12 max-[767px]:mt-7 gup_ev">
                {Corporategroupsd.map((itmes) => (

                    <div className="container">
                        <section className="text-center container  px-8">
                            <h3 className="AkagiPro-Black text-30 mb-0">{t(itmes.Corporatename)}</h3>
                            <h1 className="text-black text-48 Alfarn capitalize"  >{t(itmes.textcontant)}</h1>
                        </section>
                        <div className="md:mt-20 max-[767px]:mt-5">
                            <div className="flex justify-center ">
                          
                                <div onClick={() =>
                  navigate("/fiddlerpdf", {
                    
                  })
                } className="w-[55%]  max-[767px]:-ml-[11px] colleagues cursor-pointer" >
                                    <div className="animation_img h-full">
                                        <LazyLoadImage src={itmes.img} className="img_animation" style={{ height: "100%", objectFit: "cover" }} />
                                    </div>
                                </div>
                               
                                <div className="w-[60%]  colleagues1 md:-ml-[5%] max-[767px]:-ml-[10%] z-10 relative flex  items-center py-3">
                                    <div className="EVENTS_L flex items-center">
                                        <div className="bg-white GROUPS_ev black_shoadow md:p-[35px] max-[767px]:p-[15px] max-[767px]:min-h-[100px]  md:min-h-[300px] flex items-center" style={{

                                            backgroundImage: 'url(' + Icons.Birds + ')',
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat"
                                        }}>
                                         <div>
                                            <h1 className="text-black text-48 Akagi-Pro-blacks md:-mt-2 max-[767px]:-mt-2" style={{ lineHeight: "1.2", textTransform: `${itmes.capitalize}` }}>{t(itmes.Corporate)}</h1>
                                            <p className="text-20 md:mt-4 AkagiPro-book">
                                                {t(itmes.fabulous)}
                                            </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="flex justify-center md:mt-20 max-[767px]:mt-5">

                                <div style={{ width: "60%", marginRight: "-9%" }} className=" colleagues1 z-10 relative flex  items-center py-3">
                                    <div className="EVENTS_L left_EVENTS_L">
                                        <div className="bg-white GROUPS_ev black_shoadow   md:p-[35px] max-[767px]:p-[15px] max-[767px]:min-h-[100px]  md:min-h-[300px] " style={{

                                            backgroundImage: 'url(' + Icons.Birds + ')',
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat"
                                        }}>
                                            <div className="flex justify-end w-full">
                                              
                                            </div>
                                            <h1 className="text-black text-48 Akagi-Pro-blacks  md:-mt-1   max-[767px]:-mt-2 text-right" style={{ lineHeight: "1.2", textTransform: `${itmes.capitalize}` }}>{t(itmes.Wedding)}</h1>

                                            <p className="text-20 md:mt-4  text-justify md:pl-6 AkagiPro-book">
                                                {t(itmes.Romantic)}
                                            </p>
                                            <div className="flex justify-end w-full">
                                             
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ width: "55%" }} className=" colleagues relative max-[767px]:-right-[11px]" >
                                    <div className="animation_img h-full">
                                        <LazyLoadImage src={itmes.imgs} className="img_animation" style={{ height: "100%", objectFit: "cover" }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    )
}
export default ContactGroup;