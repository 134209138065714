import axios from "axios";
import {
  ApiKey,
  BaseApiUrl,
  SenderEmail,
  SenderName,
  ToEmail,
  ToName,
} from "./Until";

const SendEmail = async (formData) => {
  const htmlContent = `<html>
<head>
  <style>
    table {
    }
    th {
      text-align: left;
    }
    th.message-header {
      padding-right: 5px;
    }
  </style>
</head>
<body>
<p>Hi, New Inquiry from ${formData?.email}</p>
  <table>
    <tr>
      <th>Name:</th>
      <td>${formData?.name}</td>
    </tr>
    <tr>
      <th>Phone:</th>
      <td>${formData?.phone}</td>
    </tr>
    <tr>
      <th>Email:</th>
      <td>${formData?.email}</td>
    </tr>
     <tr>
      <th>Subject:</th>
      <td>${formData?.subject}</td>
    </tr>
    <tr>
      <th class="message-header">Message:</th>
      <td>${formData?.message}</td>
    </tr>
  </table>
</body>
</html>`;

  const data = {
    sender: {
      name: SenderName,
      email: SenderEmail,
    },
    to: [
      {
        email: ToEmail,
        name: ToName,
      },
    ],
    subject: "New Inquiry from fiddlerlakeresort.com",
    htmlContent,
  };

  try {
    const response = await axios.post(BaseApiUrl, data, {
      headers: {
        accept: "application/json",
        "api-key": ApiKey,
        "content-type": "application/json",
      },
    });
    console.log("Email sent successfully:", response.data);
    return response.data; // Optionally return the response data if needed
  } catch (error) {
    console.error("Error sending email:", error);
    throw error; // Throw the error to handle it where the function is called
  }
};

export default SendEmail;
