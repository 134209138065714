import axios from "axios";
import {
  ApiKey,
  BaseApiUrl,
  SenderEmail,
  SenderName,
  ToEmail,
  ToName,
} from "./Until";

const ReceiveEmail = async (formData) => {
  const htmlContent = `<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Thank You | Fiddler Lake Resort</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      margin: 0;
      padding: 20px;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    th {
      text-align: left;
    }
    th.message-header {
      padding-right: 5px;
    }
    p {
      margin: 10px 0;
    }
  </style>
</head>
<body>
  <p>Thank you for contacting Fiddler Lake Resort. Our support team will get back to you shortly. We appreciate your patience!</p>
  <br/>
  <p>Best Regards,</p>
  <p>The Fiddler Lake Resort Team</p>
</body>
</html>`;

  const data = {
    sender: {
      name: SenderName,
      email: SenderEmail,
    },
    to: [
      {
        email: formData?.email,
        name: formData?.name,
      },
    ],
    subject: "Request Confirmation From Fiddler Lake Resort",
    htmlContent,
  };

  try {
    const response = await axios.post(BaseApiUrl, data, {
      headers: {
        accept: "application/json",
        "api-key": ApiKey,
        "content-type": "application/json",
      },
    });
    console.log("Email sent successfully:", response.data);
    return response.data; // Optionally return the response data if needed
  } catch (error) {
    console.error("Error sending email:", error);
    throw error; // Throw the error to handle it where the function is called
  }
};

export default ReceiveEmail;
