import React from "react";
import Icons from "../../../Constants/Icon";
import Bookfooter from "../../../component/footer/Bookfooter";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import BookNow from "../../../component/Button/BookNow";
import { useState } from "react";
import  BackgroundImage from "../../../component/backgroundtext/backgroundimg";
import { marker } from "leaflet";
import { LazyLoadImage } from "react-lazy-load-image-component";
const rabbit = [
    {
        name: "rabbit",
        Image: Icons.Union,
        id: "1",
        height:"45px",
        marker:"auto"
    }, {
        name: "bear",
        Image: Icons.Union1,
        id: "2",
         height:"39px"
    }, {
        name: "deer",
        Image: Icons.Union2,
        id: "3",
        marginLeft:"5px",
         height:"45px"
    }, {
        name: "moose",
        Image: Icons.Union3,
        id: "4",
        marginLeft:"10px",
         height:"45px"
    },
    {
        name: "elk",
        Image: Icons.Union4,
        id: "5",
        marginLeft:"16px",
         height:"45px"
    },
]
const Moose = [
    {
        homeimg: Icons.Rabbit,
        typeof: "rabbit",
        feature: "These single-storey open-concept chalets with cathedral ceilings offer a feeling of warmth and coziness. These log homes feature a fully equipped kitchen, a circular dining room, two bathrooms, and a stone fireplace. Most Rabbit chalets have a private outdoor hot tub. A spectacular view of nature is offered by the magnificent windows."
        , rooms: "Description of rooms",
        models: "Most of our Rabbit models have three bedrooms, one with a queen-size bed and two others with two single beds each. Bedroom layout and types of beds can differ from one chalet to another.",
        name: "rabbit",
        DeerImg: Icons.Vectorcagaru,
        guest: "6-8",
        roomss: "3-4 ",
        ExtraBeds: "0",
        bathroom: "2",
        Iniventory: "2",
        id: "1",

    },
    {
        homeimg: Icons.Bear,
        typeof: "Bear",
        DeerImg: Icons.Vectorrish,
        feature: "These two-storey chalets each have a fully equipped kitchen, a dining room, two bathrooms, and a living room with a stone fireplace. This log home’s main floor features a cozy family room and a sofa or a sofa bed (depending on the chalet). With a cathedral ceiling and majestic windows, the space is bathed in natural light and offers magnificent views of the forest.",
        rooms: "Description of rooms",
        name: "Bear",
        models: "Most of our Bear models feature a master bedroom with king-size bed and en suite bathroom, a bedroom with two single beds and a third bedroom on the first floor with a queen-size bed. Bedroom layout and types of beds can differ from one chalet to another.",
        guest: "8-10",
        roomss: "3-4",
        ExtraBeds: "0-1",
        bathroom: "2",
        Iniventory: "6",
        id: "2",
        widthd:"73px"
    },
    {
        homeimg: Icons.Deer,
        typeof: "rabbit",
        DeerImg: Icons.Vector8,
        feature: "Each two-storey Deer chalet comes with a fully equipped kitchen, a dining room with a large round table, a living room with a stone fireplace and three bathrooms. Tall cathedral ceilings and majestic windows enhance the space, providing plenty of natural light. The basement is designed for fun and features a large living room, and a pool table. Most of these log homes also have a private hot tub.",
        rooms: "Description of rooms",
        name: "deer",
        models: "Deer models have two bedrooms on the main floor with king and/or queen-sized beds. The basement has two or three bedrooms as well as up to three sofa beds. The bedroom layout and types of beds may vary from one chalet to another.",
        guest: "8-16",
        roomss: "4-5 ",
        ExtraBeds: "0-3",
        bathroom: "3-3.5",
        Iniventory: "22",
        id: "3",
    },
    {
        homeimg: Icons.Moose,
        typeof: "Bear",
        DeerImg: Icons.Vector9,
        feature: "These open-concept chalets feature 15-inch log framing, creating a majestic and impressive structure. Our two-storey Moose chalets feature a fully equipped kitchen, dining room, three or four bathrooms, a living room with a stone fireplace, and naturally heated floors.",
        rooms: "Description of rooms",
        name: "Moose",
        models: "<div>Our Moose chalets have two bedrooms on the main floor, one with a king-size bed. The second floor features a mezzanine where you’ll find two other master bedrooms, each equipped with a king-size bed and en suite bathroom.<div class='md:mt-4 max-[767px]:mt-3'>The bedroom layout and types of beds may vary from one chalet to another.</div></div>",
        guest: "12-18",
        roomss: "4-6",
        ExtraBeds: "0-2",
        bathroom: "3-4",
        Iniventory: "5 ",
        id: "4",
        widthd:"58px"
    },
    {
        homeimg: Icons.Elk,
        typeof: "rabbit",
        DeerImg: Icons.rabbit7,
        feature: "This magnificent chalet is the largest of them all, offering abundant luxury and spaciousness. This log home features a fully equipped kitchen, a large dining room, living room with a stone fireplace, and four bathrooms. In the basement, you’ll find a large family room with a pool table. High cathedral ceilings provide a sense of airiness, and majestic windows offer a breathtaking view of nature.",
        rooms: "Description of rooms",
        name: "elk",
        models: "Our Elk chalet features a master bedroom on the main floor with a king-size bed, connecting bathroom, and a private balcony. On the same floor, you’ll find a second bedroom furnished with a queen-size bed. As you descend to the basement, you will find three other bedrooms. One comes equipped with a king-size bed and a connecting bathroom, and the two each have a double bed. For extra flexibility and convenience, two retractable double beds are also available in the basement living room.",
        guest: "18",
        roomss: "5",
        ExtraBeds: "2",
        bathroom: "4",
        Iniventory: "1",
        id: "5",
    },
]

const Sauveur = [{
    name: "OUR CHALETS",
    height: "100vh",
    bgimg: Icons.ChaletsBanner,
    Rentals:"Enjoy The Superior Comfort <br/> And Rustic Charm Of A Log Home"
}];
function Discover(props) {
    const [selectedItem, setSelectedItem] = useState();
    const handleItemClick = (items, id, delay) => {
        setSelectedItem(items);
        const element = document.getElementById(id);
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth' });
            }, delay);
        }
       
    };
    const itemsid = props.itemsid
    setTimeout(() => {
        const div = document.getElementById(itemsid);
        if (div) {
            window.scrollTo({
                top: div.offsetTop - 220, 
                behavior: 'smooth'
            })}
        // if (div) {
        //   div.scrollIntoView({ behavior: 'smooth' });
        // }
        
      }, 1000); 

      const { t, i18n } = useTranslation();
      const currentLanguageCode = i18n.language;
      const urls = {
          en: "https://company-15146508.staycation.igms.com/?lang=en",
          fr: "https://company-15146508.staycation.igms.com/?lang=fr"
      };
      const currentUrl = urls[currentLanguageCode] || urls.en;
    return (
        <div className="descover_3" >
             <div className='family_fillte darkdata'>
                <BackgroundImage Sauveur={Sauveur} />
            </div>
            <section className="text-center container  px-8 py-4">
             <h1 className="text-black mt-2 md:mb-5  text-64 Alfarn">{t("Discover our chalets")}</h1>
                <p className="text-20 AkagiPro-book md:mt-4">{t("Escape to one of our luxurious chalets, each boasting floor-to-ceiling windows, a stone fireplace, and an outdoor patio equipped with a BBQ.")}</p>
                <p className="text-20 AkagiPro-book md:mt-4" dangerouslySetInnerHTML={{ __html: t("Our models range in size from three to six rooms, and depending on the chalet chosen, additional features may include a three- or four-season veranda,<br />private hot tub, private sauna, pool table, or even a private pool.") }} />
                <p className="text-20 AkagiPro-book md:mt-4">{t("Read each model’s description for specific features and detailed information.")}</p>
                <div className= " max-w-[800px] mx-auto bg-lightyellow rebit_ar flex gap-4 justify-between items-end md:px-20 md:py-4  max-[767px]:px-10 max-[767px]:py-3 md:mt-9 max-[767px]:mt-7 max-[767px]:gap-5 md:rounded-[30px] max-[767px]:rounded-[30px] max-[365px]:gap-2 max-[365px]:px-4 lg:mx-32" >
                    {rabbit.map((itmes) =>
                    (
                        <div className={`leftIcone ${selectedItem === itmes ? "active_left" : "cursor-pointer rabbit_data "}`} onMouseOver={() => handleItemClick(itmes, itmes.id,500)} >
                            <LazyLoadImage src={itmes.Image} style={{ margin:`${itmes.marker}`,maxHeight:`${itmes.height}`,}} />
                            <div className="capitalize text-sm Alfarn  text-white mt-2 text-10" style={{marginLeft:`${itmes.marginLeft}`,fontWeight:"100"}} >{t(itmes.name)}</div>
                        </div>
                    )
                    )}
                </div>
            </section>
            <section className="md:mb-20 max-[767px]:mb-10"  >
                <div className="container">
                    {Moose.map((itmes) =>
                    (
                        <div >
                            {itmes.typeof == "rabbit" && (
                                <div className="md:flex justify-center md:mt-16 max-[767px]:mt-2" >
                                    <div className="md:w-[45%] md:-mr-[126px] overflow-hidden animation_img md:-ml-4 flex items-center" >
                                        <LazyLoadImage src={itmes.homeimg} style={{width:"100%" , height:"100%",objectFit: "cover" }} className="img_animation" />
                                    </div>
                                    <div className=" md:w-[55%] z-10 relative flex  items-center md:py-3 max-[767px]:px-3 max-[767px]:pl-7" id={itmes.id}>
                                        <div className="EVENTS_L" style={{ width: "100%", paddingRight: "0px", right: "30px", }}>
                                            <div className="bg-white GROUPS_ev black_shoadow md:p-[35px] max-[767px]:p-6" style={{

                                                backgroundImage: 'url(' + Icons.Birds + ')',
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                right: "-30px",
                                                width: "100%",

                                            }}>
                                                <h1 className="text-black text-48 Alfarn flex items-baseline md:gap-4 max-[767px]:gap-3 capitalize" style={{ lineHeight: "53px" }}>{t(itmes.name)} <LazyLoadImage src={itmes.DeerImg} style={{width:`${itmes.widthd}`}} className="DeerImg" /></h1>
                                                <div className="flex flex-wrap justify-between md:gap-3 max-[767px]:gap-1 md:my-4 max-[767px]:my-3">
                                                    <div className="text-center  max-[767px]:grid items-center max-[767px]:min-h-10"   >
                                                        <p className="text-20 capitalize mb-0  AkagiPro-book">{t("guests")}</p>
                                                        <p className="text-20  mb-0 AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.guest)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} />
                                                    <div className="text-center  max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20 capitalize mb-0  AkagiPro-book">{t("rooms")}</p>
                                                        <p className="text-20   mb-0 AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.roomss)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} />
                                                    <div className="text-center  max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0  AkagiPro-book">{t("Extra Beds")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.ExtraBeds)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} />
                                                    <div className="text-center  max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0  AkagiPro-book ">{t("Bathrooms")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.bathroom)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} />
                                                    <div className="text-center  max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20 capitalize mb-0   AkagiPro-book">{t("Inventory")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.Iniventory)}</p>
                                                    </div>
                                                </div>
                                                <p className="text-20  AkagiPro-book">
                                                    {t(itmes.feature)}
                                                </p>
                                                <h3 className="AkagiPro-Black text-30 max-[767px]:mt-3 lg:mt-0 md:mt-3 " >{t(itmes.rooms)}</h3>
                                                <p className="text-20  AkagiPro-book ">
                                                    {t(itmes.models)}
                                                </p>
                                                {BookNow.map((BookNow) => (<Link to={currentUrl}>    <button className='book_now text-20  md:mt-7 max-[767px]:mt-2 max-[767px]:-bottom-3 relative book_button1  AkagiPro-Black text-white md:px-10 max-[767px]:px-5 max-[767px]:py-1 md:py-3 uppercase' >{t("book now")}</button></Link>))}
                                            </div>

                                        </div>
                                    </div>

                                </div>)}

                            {itmes.typeof == "Bear" && (
                                <div className="flex dezcover_s  justify-center md:mt-16 max-[767px]:mt-5"  >

                                    <div className="md:w-[55%] max-[767px]:w-full z-10 relative flex  items-center py-3 max-[767px]:px-3 max-[767px]:pl-7" id={itmes.id}>
                                        <div className="EVENTS_L left_EVENTS_L" style={{ right: "-60px" }}>
                                            <div className="bg-white GROUPS_ev black_shoadow md:p-[35px] max-[767px]:p-6" style={{

                                                backgroundImage: 'url(' + Icons.Birds + ')',
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                right: "-30px",
                                                width: "100%",

                                            }}>
                                                <h1 className="text-black text-48 Alfarn flex items-baseline md:gap-4 max-[767px]:gap-3" style={{ lineHeight: "53px" }}>{t(itmes.name)} <LazyLoadImage src={itmes.DeerImg} style={{width:`${itmes.widthd}`}}  className="DeerImg" /></h1>
                                                <div className="flex flex-wrap justify-between md:gap-3 max-[767px]:gap-1 md:my-4 max-[767px]:my-3">
                                                    <div className="text-center  max-[767px]:grid items-center"   >
                                                        <p className="text-20 mb-0 capitalize AkagiPro-book">{t("guests")}</p>
                                                        <p className="text-20  mb-0 AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.guest)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} />
                                                    <div className="text-center  max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20 capitalize  mb-0  AkagiPro-book">{t("rooms")}</p>
                                                        <p className="text-20   mb-0 AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.roomss)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} />
                                                    <div className="text-center max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20   mb-0  AkagiPro-book">{t("Extra Beds")}</p>
                                                        <p className="text-20   mb-0  AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.ExtraBeds)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} />
                                                    <div className="text-center  max-[767px]:grid items-center  max-[767px]:min-h-10">
                                                        <p className="text-20   mb-0  AkagiPro-book">{t("Bathrooms")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.bathroom)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} />
                                                    <div className="text-center  max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20 capitalize  mb-0   AkagiPro-book">{t("Inventory")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book text-lightyellow max-[767px]:-mt-3">{t(itmes.Iniventory)}</p>
                                                    </div>
                                                </div>
                                                <p className="text-20  AkagiPro-book">
                                                    {t(itmes.feature)}
                                                </p>
                                                <h3 className="AkagiPro-Black text-30 max-[767px]:mt-3 lg:mt-0 md:mt-3" >{t(itmes.rooms)}</h3>
                                                <p className="text-20  AkagiPro-book ">
                                                    <div dangerouslySetInnerHTML={{ __html: t(itmes.models) }} />
                                                </p>
                                                {BookNow.map((BookNow) => (<Link to={currentUrl}>   <button className='book_now text-20  md:mt-7 max-[767px]:mt-2 max-[767px]:-bottom-3 relative book_button1  AkagiPro-Black text-white md:px-10 max-[767px]:px-5 max-[767px]:py-1 md:py-3 uppercase' >{t("book now")}</button></Link>))}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="md:w-[45%] md:-ml-[126px] max-[767px]:w-full overflow-hidden animation_img  md:-mr-4 flex items-center" >
                                        <LazyLoadImage src={itmes.homeimg} style={{ width: "100%", height:"100%" ,objectFit: "cover" }} className="img_animation" />
                                    </div>

                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section >
            <Bookfooter />
        </div >

    )
}
export default Discover;