import React from "react";
import Icons from "../../../Constants/Icon";
import Bookfooter from "../../../component/footer/Bookfooter";
import { useTranslation } from 'react-i18next';
import BookNow from "../../../component/Button/BookNow";
import { Link } from "react-router-dom";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
const imageUrl = Icons.bookimg
const rabbit = [
    {
        name: "rabbit",
        Image: Icons.rabbit1,
        id: "1"
    }, {
        name: "bear",
        Image: Icons.rabbit2,
        id: "2"
    }, {
        name: "deer",
        Image: Icons.rabbit3,
        id: "3"
    }, {
        name: "moose",
        Image: Icons.rabbit4,
        id: "4"
    },
    {
        name: "elk",
        Image: Icons.rabbit8,
        id: "5"
    },
]
const Moose = [
    {
        homeimg: Icons.Discovers1,
        typeof: "rabbit",
        feature: "These open-concept chalets with cathedral ceilings feature a fully equipped kitchen, circular dining room, two bathrooms, and living room with stone fireplace. The majestic windows allow for a spectacular view of nature. Most Rabbit chalets have a private outdoor sauna and hot tub."
        , rooms: "Description of rooms",
        models: "Most of our Rabbit models have three bedrooms: one with a queen bed, the other two with two single beds in each. The bedroom layout and types of beds may vary from one chalet to another.",
        name: "rabbit",
        DeerImg: Icons.Vectorcagaru,
        guest: "6 or 8",
        roomss: "3 or 4",
        ExtraBeds: "0",
        bathroom: "2 or 3",
        Iniventory: "4",
        id: "1"

    },
    {
        homeimg: Icons.Discovers2,
        typeof: "Bear",
        DeerImg: Icons.Vectorrish,
        feature: "These chalets have a fully equipped kitchen, dining room, two bathrooms, and living room with a stone fireplace. The main floor also features a small family room with a TV and sofa bed (depends on chalet). The cathedral ceilings and majestic windows allow for a spectacular view of nature.",
        rooms: "Description of rooms",
        name: "Bear",
        models: "Most of our Bear models have one master bedroom with a king bed and en suite bathroom, one bedroom with two single beds, and a third bedroom on the main floor with a king bed. The bedroom layout and types of beds may vary from one chalet to another.",
        guest: "8",
        roomss: "3 or 4",
        ExtraBeds: "0 or 1",
        bathroom: "2",
        Iniventory: "13",
        id: "2"
    },
    {
        homeimg: Icons.Discovers3,
        typeof: "rabbit",
        DeerImg: Icons.rabbit5,
        feature: "These two-storey chalets have a fully equipped kitchen, dining room with a large round table, three bathrooms, and living room with stone fireplace. The basement is designed for fun with a large recreation room, home theatre centre, and a pool table. The cathedral ceilings and majestic windows allow for plenty of natural light. These chalets all have a hot tub and most also have an outdoor sauna.",
        rooms: "Description of rooms",
        name: "deer",
        models: "There are two bedrooms with king and/or queen beds on the main floor. The basement has two or three bedrooms, as well as up to three sofa beds. The bedroom layout and types of beds may vary from one chalet to another.",
        guest: "8,9,10,12,14,16",
        roomss: "3 or 4",
        ExtraBeds: "0",
        bathroom: "2 or 3",
        Iniventory: "4",
        id: "3"
    },
    {
        homeimg: Icons.Discovers4,
        typeof: "Bear",
        DeerImg: Icons.rabbit6,
        feature: "These open-concept chalets are built with 15-inch logs, creating a majestic and impressive structure. This luxurious chalet features a fully equipped kitchen, dining room, three bathrooms, living room with stone fireplace, and naturally heated floors.",
        rooms: "Description of rooms",
        name: "moose",
        models: "There are two bedrooms on the main floor: one with a king bed, the other with two or three single beds. The first floor has a mezzanine where two other master bedrooms are located, each equipped with a king bed and en suite bathroom. The bedroom layout and types of beds may vary from one chalet to another.",
        guest: "9,12,18",
        roomss: "4,5,6",
        ExtraBeds: "0 or 1",
        bathroom: "3 or 4",
        Iniventory: "3",
        id: "4"
    },
    {
        homeimg: Icons.Discovers5,
        typeof: "rabbit",
        DeerImg: Icons.rabbit7,
        feature: "These magnificent chalets are the largest of them all and feature a fully equipped kitchen, dining room, living room with stone fireplace, and four bathrooms. In the basement, there is a large family room with a home theatre centre and pool table. The cathedral ceilings and majestic windows allow for a spectacular view of nature.",
        rooms: "Description of rooms",
        name: "elk",
        models: "The master bedroom, on the main floor, has a king bed, a connecting bathroom and a private balcony. On the same floor, the second bedroom is furnished with a queen size bed. In the basement, there are three other rooms. One has a king bed with a connecting bathroom, and the other two have a double bed each. Two retractable double beds are also available in the basement living room. The configuration of the rooms and the types of beds may vary from one cottage to another.",
        guest: "18",
        roomss: "5",
        ExtraBeds: "2",
        bathroom: "4",
        Iniventory: "1",
        id: "5"
    },
]
function Discover() {
    const [selectedItem, setSelectedItem] = useState();
    const { t } = useTranslation();
    const handleItemClick = (itmes, id) => {
        setSelectedItem(itmes);
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className="descover_3" style={{ background: "black" }}>
            <section className="text-center container  px-8 py-4 text-white">
                <div className="flex justify-center w-full md:mt-7 -mb-6">
                    <div className='flex items-center gap-8 line-des1'>
                        <div className='w-16  bg-white  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                        <h3 className="AkagiPro-Black text-32  ">{t("FIDDLER LAKE RESORT")}</h3>
                    </div>
                </div>
                <h1 className="text-white md:mt-4 max-[767px]:mt-5 md:mb-8 max-[767px]:mb-3 text-64 Alfarn" style={{ lineHeight: "66px" }}>{t("Discover our chalets")}</h1>
                <p className="text-20 AkagiPro-book">{t("Fiddler Lake Resort offers chalet rentals in the Laurentians. An ideal destination for getaways with friends, family gatherings, or team-building activities. Our more than 50 chalets are all individually owned, hence uniquely different from one another. While chalets from each model are similar in style, it is important to read individual model descriptions for specific features.")}</p>

                <div className="bg-skyYellow flex justify-between items-center md:px-20 md:py-7  max-[767px]:px-10 max-[767px]:py-5 md:mt-9 max-[767px]:mt-7 max-[767px]:gap-5 md:rounded-[50px] max-[767px]:rounded-[20px] max-[365px]:gap-2 max-[365px]:px-4 md:mx-12 " >
                    {rabbit.map((itmes) =>
                    (
                        <div className={`leftIcone ${selectedItem === itmes ? "active_left" : "cursor-pointer rabbit_data"}`} onMouseOver={() => handleItemClick(itmes, itmes.id)}>
                            <LazyLoadImage src={itmes.Image} />
                            <div className="capitalize text-20 AkagiPro-book text-lightyellow mt-2">{t(itmes.name)}</div>
                        </div>
                    )
                    )}
                </div>
            </section>
            <section className="md:mb-20 max-[767px]:mb-10">
                <div className="container">
                    {Moose.map((itmes) =>
                    (
                        <div>
                            {itmes.typeof == "rabbit" && (
                                <div className="md:flex justify-center md:mt-16 max-[767px]:mt-2">
                                    <div className="md:w-[50%] md:-mr-[126px] overflow-hidden animation_img" >
                                        <LazyLoadImage src={itmes.homeimg} style={{ height: "100%", objectFit: "cover" }} className="img_animation" />
                                    </div>
                                    <div className=" md:w-[60%] z-10 relative flex  items-center md:py-3 max-[767px]:px-3 max-[767px]:pl-7" id={itmes.id}>
                                        <div className="EVENTS_L" style={{ width: "100%", paddingRight: "0px", right: "30px", }}>
                                            <div className="bg-lightyellow GROUPS_ev black_shoadow md:p-[35px] max-[767px]:p-6 text-white" style={{

                                                backgroundImage: 'url(' + Icons.textu + ')',
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                right: "-30px",
                                                width: "100%",
                                                backgroundColor: "#BD8833"

                                            }}>
                                                <h1 className="text-48 Alfarn flex items-center md:gap-4 max-[767px]:gap-3 text-white" style={{ lineHeight: "53px" }}>{t(itmes.name)} <LazyLoadImage src={itmes.DeerImg} style={{ filter: "brightness(0) invert(1)" }} className="DeerImg " /></h1>
                                                <div className="flex flex-wrap justify-between md:gap-3 max-[767px]:gap-1 md:my-4 max-[767px]:my-3">
                                                    <div className="text-center capitalize max-[767px]:grid items-center max-[767px]:min-h-10"   >
                                                        <p className="text-20 mb-0  AkagiPro-book">{t("guest")}</p>
                                                        <p className="text-20  mb-0 AkagiPro-book  max-[767px]:-mt-3">{t(itmes.guest)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} style={{ filter: "brightness(0) invert(1)" }} />
                                                    <div className="text-center capitalize max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0  AkagiPro-book">{t("rooms")}</p>
                                                        <p className="text-20   mb-0 AkagiPro-book  max-[767px]:-mt-3">{t(itmes.roomss)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} style={{ filter: "brightness(0) invert(1)" }} />
                                                    <div className="text-center capitalize max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0  AkagiPro-book">{t("Extra Beds")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book  max-[767px]:-mt-3">{t(itmes.ExtraBeds)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} style={{ filter: "brightness(0) invert(1)" }} />
                                                    <div className="text-center capitalize max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0  AkagiPro-book">{t("bathroom")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book t max-[767px]:-mt-3">{t(itmes.bathroom)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} style={{ filter: "brightness(0) invert(1)" }} />
                                                    <div className="text-center capitalize max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0   AkagiPro-book">{t("Iniventory")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book  max-[767px]:-mt-3">{t(itmes.Iniventory)}</p>
                                                    </div>
                                                </div>
                                                <p className="text-20  AkagiPro-book">
                                                    {t(itmes.feature)}
                                                </p>
                                                <h3 className="AkagiPro-Black text-30 max-[767px]:mt-3 lg:mt-0 md:mt-3 " >{t(itmes.rooms)}</h3>
                                                <p className="text-20  AkagiPro-book ">
                                                    {t(itmes.models)}
                                                </p>
                                                {BookNow.map((BookNow) => (<Link to={BookNow.Link}> <button className='text-30  button_blur md:mt-7 max-[767px]:mt-2 max-[767px]:-bottom-3 relative book_button1  AkagiPro-Black text-lightyellow md:px-10 max-[767px]:px-5 max-[767px]:py-1 md:py-3 uppercase' style={{ backgroundColor: "white", color: "#BD8833" }} >{t("book now")}</button></Link>))}
                                            </div>

                                        </div>
                                    </div>

                                </div>)}

                            {itmes.typeof == "Bear" && (
                                <div className="flex dezcover_s  justify-center md:mt-16 max-[767px]:mt-5">

                                    <div className="md:w-[60%] max-[767px]:w-full z-10 relative flex  items-center py-3 max-[767px]:px-3 max-[767px]:pl-7" id={itmes.id}>
                                        <div className="EVENTS_L left_EVENTS_L" style={{ right: "-60px" }}>
                                            <div className="bg-lightyellow GROUPS_ev black_shoadow md:p-[35px] max-[767px]:p-6 text-white" style={{

                                                backgroundImage: 'url(' + Icons.textu + ')',
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                right: "-30px",
                                                width: "100%",

                                            }}>
                                                <h1 className=" text-48 Alfarn flex items-center md:gap-4 max-[767px]:gap-3 text-white capitalize" style={{ lineHeight: "53px" }}>{t(itmes.name)} <LazyLoadImage src={itmes.DeerImg} className="DeerImg" style={{ filter: "brightness(0) invert(1)" }} /></h1>
                                                <div className="flex flex-wrap justify-between md:gap-3 max-[767px]:gap-1 md:my-4 max-[767px]:my-3">
                                                    <div className="text-center capitalize max-[767px]:grid items-center"   >
                                                        <p className="text-20 mb-0  AkagiPro-book">{t("guest")}</p>
                                                        <p className="text-20  mb-0 AkagiPro-book  max-[767px]:-mt-3">{t(itmes.guest)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} style={{ filter: "brightness(0) invert(1)" }} />
                                                    <div className="text-center capitalize max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0  AkagiPro-book">{t("rooms")}</p>
                                                        <p className="text-20   mb-0 AkagiPro-book  max-[767px]:-mt-3">{t(itmes.roomss)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} style={{ filter: "brightness(0) invert(1)" }} />
                                                    <div className="text-center capitalize max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0  AkagiPro-book">{t("Extra Beds")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book  max-[767px]:-mt-3">{t(itmes.ExtraBeds)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} style={{ filter: "brightness(0) invert(1)" }} />
                                                    <div className="text-center capitalize max-[767px]:grid items-center  max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0  AkagiPro-book">{t("bathroom")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book  max-[767px]:-mt-3">{t(itmes.bathroom)}</p>
                                                    </div>
                                                    <LazyLoadImage src={Icons.Line} style={{ filter: "brightness(0) invert(1)" }} />
                                                    <div className="text-center capitalize max-[767px]:grid items-center max-[767px]:min-h-10">
                                                        <p className="text-20  mb-0   AkagiPro-book">{t("Iniventory")}</p>
                                                        <p className="text-20  mb-0  AkagiPro-book max-[767px]:-mt-3">{t(itmes.Iniventory)}</p>
                                                    </div>
                                                </div>
                                                <p className="text-20  AkagiPro-book">
                                                    {t(itmes.feature)}
                                                </p>
                                                <h3 className="AkagiPro-Black text-30 max-[767px]:mt-3 lg:mt-0 md:mt-3" >{t(itmes.rooms)}</h3>
                                                <p className="text-20  AkagiPro-book ">
                                                    <div dangerouslySetInnerHTML={{ __html: t(itmes.models) }} />
                                                </p>
                                                {BookNow.map((BookNow) => (<Link to={BookNow.Link}>  <button className='button_blur text-30  md:mt-7 max-[767px]:mt-2 max-[767px]:-bottom-3 relative book_button1  AkagiPro-Black text-lightyellow  md:px-10 max-[767px]:px-5 max-[767px]:py-1 md:py-3 uppercase' style={{ backgroundColor: "white", color: "#BD8833" }}>{t("book now")}</button></Link>))}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="md:w-[50%] md:-ml-[126px] max-[767px]:w-full overflow-hidden animation_img" >
                                        <LazyLoadImage src={itmes.homeimg} style={{ height: "100%", objectFit: "cover" }} className="img_animation" />
                                    </div>

                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section >
            <Bookfooter imageUrl={imageUrl} />
        </div >

    )
}
export default Discover;