import React, { useState } from "react";
import Icons from "../../Constants/Icon";
import { useTranslation } from "react-i18next";
import SendEmail from "../../Core/SendEmail";
import { useDispatch } from "react-redux";
import ReceiveEmail from "../../Core/ReceiveEmail";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ContactForm = ({setLoading}) => {
    
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
const dispatch  = useDispatch()
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number is invalid";
    }
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }

    if (Object.keys(newErrors).length === 0) {
      console.log("Form submitted:", formData);

      try {
        setLoading(true)
        const response = await SendEmail(formData);
        const responseReceive =  await ReceiveEmail(formData)
        
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
        setLoading(false)
        setShowSuccessPopup(true);
        setErrors({});
        setTimeout(() => {
          setShowSuccessPopup(false);
        }, 2000);
      } catch (error) {setLoading(false)}
    } else {
        setLoading(false)
      setErrors(newErrors);
      setShowSuccessPopup(false);
    }
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
  };
  const { t } = useTranslation();
  return (
    <div className="" style={{}}>
      <div>
        <form onSubmit={handleSubmit} className="space-y-4 contact_form">
          <div className="grid grid-cols-2 md:gap-3 max-[767px]:gap-2 AkagiPro-book ">
            <div className="capitalize">
              <input
                type="text"
                id="name"
                name="name"
                placeholder={t("Enter your name")}
                value={formData.name}
                onChange={handleChange}
                className={`p-3 px-4 text-20  placeholder:text-black placeholder:text-xl placeholder:  text-black w-full mt-1  border ${
                  errors.name ? "border-red-500" : "border-gray-300"
                } focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
              />
              {errors.name && (
                <p className="text-red-500 mt-1">{t(errors.name)}</p>
              )}
            </div>
            <div className="capitalize" >
              <input
                type="email"
                id="email"
                name="email"
                placeholder={t("Enter your email")}
                value={formData.email}
                onChange={handleChange}
                className={`p-3 px-4 text-20 placeholder:text-black placeholder:text-xl placeholder: w-full  text-black  mt-1  border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                }  focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
              />
              {errors.email && (
                <p className="text-red-500 mt-1">{t(errors.email)}</p>
              )}
            </div>
            <div className="capitalize">
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                placeholder={t("Enter your phone no.")}
                onChange={handleChange}
                className="p-3 px-4 text-20 placeholder:text-black placeholder:text-xl placeholder:  text-black w-full mt-1  border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
              {errors.phone && (
                <p className="text-red-500 mt-1">{t(errors.phone)}</p>
              )}
            </div>
            <div>
              <div className="relative mt-1 text-xl  ">
                <div
                  type="button"
                  className={`  relative text-black w-full md:p-4 max-[767px]:pl-7 p-[9px] md:px-4 text-20 text-left border texzt_ex ${
                    errors.subject ? "border-red-500" : "border-gray-300"
                  }  focus:outline-none focus:ring-blue-500 focus:border-blue-500 bg-skyYellow`}
                  onClick={() =>
                    document
                      .getElementById("subject-options")
                      .classList.toggle("hidden")
                  }
                >
                  {t(formData.subject || "Subject")}
                  <LazyLoadImage
                    src={Icons.arror_down}
                    className="absolute right-6 md:top-6 top-3 subject"
                  />
                </div>
                <ul
                  id="subject-options"
                  className="absolute z-10 left-0 mt-2 max-[767px]:pl-0 w-full bg-white rounded-md shadow-lg border border-gray-300 hidden"
                >
                  <li
                    onClick={() => {
                      setFormData({
                        ...formData,
                        subject: "Chalet Reservation",
                      });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 py-2 text-20 hover:bg-gray-100 cursor-pointer"
                  >
                    {t("Chalet Reservation")}
                  </li>
                  <li
                    onClick={() => {
                      setFormData({
                        ...formData,
                        subject: "Family & Friends Celebration",
                      });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 text-20 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {t("Family & Friends Celebration")}
                  </li>
                  <li
                    onClick={() => {
                      setFormData({ ...formData, subject: "Corporate Group" });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 py-2 text-20 hover:bg-gray-100 cursor-pointer"
                  >
                    {t("Corporate Group")}
                  </li>
                  <li
                    onClick={() => {
                      setFormData({ ...formData, subject: " Wedding" });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 py-2 text-20 hover:bg-gray-100 cursor-pointer"
                  >
                    {t(" Wedding")}
                  </li>
                  <li
                    onClick={() => {
                      setFormData({ ...formData, subject: "General Inquiry" });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 py-2 text-20 hover:bg-gray-100 cursor-pointer"
                  >
                    {t("General Inquiry")}
                  </li>
                </ul>
              </div>
              {errors.subject && (
                <p className="text-red-500 mt-1">{t(errors.subject)}</p>
              )}
            </div>
          </div>
          <div className="capitalize">
            <textarea
              id="message"
              name="message"
              placeholder={t("message")}
              rows="8"
              value={formData.message}
              onChange={handleChange}
              className="p-3 px-4 text-20 placeholder:text-black placeholder:text-xl placeholder:capitalize  text-black w-full md:mt-1  max-[767px]:-mt-1 border border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            ></textarea>
            {errors.message && (
              <p className="text-red-500 mt-1">{t(errors.message)}</p>
            )}
          </div>
          <div className="flex justify-end ">
            <button
              style={{ background: "#BD8833" }}
              type="submit"
              className="max-[767px]:h-[34px] AkagiPro-Black md:px-28 max-[767px]:px-16 bg-lightyellow text-white  md:py-3  md:mt-2 max-[767px]:-mt-1  text-20  hover:text-white hover:bg-black uppercase an_button"
            >
              {t("Send")}
            </button>
          </div>
        </form>
      </div>
      {showSuccessPopup && (
        <div className="success-popup">
          <div className="success-popup-content">
          
            <h2>{t("Email sent successfully!")}</h2>
            <button
              onClick={handleCloseSuccessPopup}
              className="AkagiPro-Black"
            >
              {t("Close")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
