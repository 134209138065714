// reducers/index.js
import { combineReducers } from "redux";
import loadingReducer from "./loadingReducer";

const rootReducer = combineReducers({
  isLoading: loadingReducer,
  // other reducers here
});

export default rootReducer;
