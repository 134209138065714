import React from "react";
import Icons from "../../Constants/Icon";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from "react-lazy-load-image-component";
function GroupsEvents({ Corporategroups }) {
    const { t } = useTranslation();
    return (
        <div className=" md:py-8 max-[767px]:mt-12 pb-0 md:mb-20 max-[767px]:mb-10 gup_ev">
            {Corporategroups.map((itmes) => (

                <div className="container">
                    <section className="text-center container  px-8">

                        <h1 className="text-black text-64 Alfarn" style={{ lineHeight: "1" }} >{t("OUR BUSINESS PACKAGES")}<br />{t("")}</h1>
                    </section>
                    <div className="md:mt-20 max-[767px]:mt-5">
                        <div className="flex justify-center ">
                            <div className="w-[55%]  max-[767px]:-ml-[11px] colleagues animation_img" >
                                <LazyLoadImage src={Icons.Resort4} className="img_animation" style={{ height: "100%", objectFit: "cover" }} />
                            </div>
                            <div className="w-[60%]  colleagues1 md:-ml-[5%] max-[767px]:-ml-[10%] z-10 relative flex  items-center py-3">
                                <div className="EVENTS_L">
                                    <div className="bg-white GROUPS_ev black_shoadow md:p-[70px] md:py-[100px] max-[767px]:p-[15px]" style={{

                                        backgroundImage: 'url(' + Icons.Birds + ')',
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat"
                                    }}>
                                        <div className="flex j w-full">
                                            {/* <div className='flex items-center md:gap-8 max-[767px]:gap-2 line-des1'>
                                                <div className='md:w-16 max-[767px]:w-5  bg-black  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                                                <h3 className="AkagiPro-Black text-30  ">{t("PACKAGE")}</h3>
                                            </div> */}
                                        </div>
                                       
                                        <h3 className="AkagiPro-Black text-30  ">{t("PACKAGE")}</h3>
                                        <h1 className="text-black text-48 Akagi-Pro-blacks   md:-mt-1 md:mb-5 max-[767px]:-mt-2" style={{ lineHeight: "0.9" }}>{t("Self-Service")}</h1>
                                        <p className="text-20 md:mt-4 AkagiPro-book">
                                            {t("Make yourself right at home with our do-it-yourself option. Stay in our chalets, organize your meetings, team building activities and meals. If you need some inspiration, our team will be delighted to suggest original ideas.")}
                                        </p>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="flex justify-center md:mt-20 max-[767px]:mt-5">

                            <div style={{ width: "60%", marginRight: "-9%" }} className=" colleagues1 z-10 relative flex  items-center py-3">
                                <div className="EVENTS_L left_EVENTS_L">
                                    <div className="bg-white GROUPS_ev black_shoadow   md:p-[70px] md:py-[100px] max-[767px]:p-[15px]" style={{

                                        backgroundImage: 'url(' + Icons.Birds + ')',
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat"
                                    }}>
                                        <div className="flex justify-end w-full">
                                            {/* <div className='flex items-center  md:gap-8 max-[767px]:gap-2 line-des1'>
                                                <div className='md:w-16 max-[767px]:w-5   bg-black  items-center mb-2 line-des' style={{ height: "1px" }} ></div>
                                                <h3 className="AkagiPro-Black text-30 ">{t("PACKAGE")}</h3>
                                            </div> */}
                                        </div>
                                       
                                        <h3 className="AkagiPro-Black text-30 text-right">{t("PACKAGE")}</h3>
                                        <h1 className="text-black text-48 Akagi-Pro-blacks md:mb-5  md:-mt-1  max-[767px]:-mt-2 text-right" style={{ lineHeight: "0.9" }}>{t("Full-Service")}</h1>

                                        <p className="text-20 md:mt-4  text-justify md:pl-6 AkagiPro-book">
                                            {t(itmes.Romantic)}
                                        </p>

                                    </div>

                                </div>
                            </div>
                            <div style={{ width: "55%" }} className=" colleagues relative max-[767px]:-right-[11px] animation_img" >
                                <LazyLoadImage src={Icons.corpo1} className="img_animation" style={{ height: "100%", objectFit: "cover" }} />
                            </div>

                        </div>
                    </div>
                </div>
            ))
            }
        </div >
    )
}
export default GroupsEvents;