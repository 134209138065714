import React, { useState } from "react";
import Icons from "../../Constants/Icon";
import { useTranslation } from "react-i18next";
import SendEmail from "../../Core/SendEmail";
import ReceiveEmail from "../../Core/ReceiveEmail";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ContactForm = ({ setLoading, contactdatanew }) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation
    let newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number is invalid";
    }
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }

    if (Object.keys(newErrors).length === 0) {
      // Form submission logic goes here

      // Clear form data
      try {
        setLoading(true);
        const response = await SendEmail(formData);
        const responseReceive = await ReceiveEmail(formData);
        setLoading(false);
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
        // Show success popup
        setShowSuccessPopup(true);
        setErrors({});
        setTimeout(() => {
          setShowSuccessPopup(false);
        }, 2000);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErrors(newErrors);
      // Hide success popup if there were errors
      setShowSuccessPopup(false);
    }
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
  };
  const { t } = useTranslation();

  return (
    <div
      className="md:py-14 max-[767px]:py-5 lg:min-h-[600px] "
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Icons.Family9})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >

      <div className="container ">
        {contactdatanew.map((itmes) => (
          <section className="text-center md:mb-10  md:px-8 md:mt-8">
            <div className="flex justify-center w-full  md:mb-6">
              <div>

                <div className="flex justify-center">
                  <div className="flex items-center md:gap-8 max-[767px]:gap-3 md:mb-2 line-des1">
                    <div
                      className="md:w-16 max-[767px]:w-5  bg-white  items-center  line-des"
                      style={{ height: "1px" }}
                    ></div>
                    <h3 className="AkagiPro-Black text-32 text-white max-[767px]:mb-0 ">
                      {t("FIDDLER LAKE RESORT")}
                    </h3>
                  </div>
                </div>
                <h1 className="text-white md:-mt-4  text-64 Alfarn ">
                  {t(itmes.name)}
                </h1>
                {itmes.planning === "" ? "" :
                <p className='text-30  AkagiPro-book text-white md:mt-9 max-[767px]:mb-0  ovg'>{t(itmes.planning)}
                  <span style={{color:"#F0D673"}}> 1-800-721-0371 </span>{t(itmes.complete)}</p>}
              </div>
            </div>
          </section>))}

        <form
          onSubmit={handleSubmit}
          className="md:space-y-4 p-3 Groups_reservation contact_form"
        >
          <div className="grid grid-cols-2 max-[352px]:grid-cols-1 md:gap-3 max-[767px]:gap-2 AkagiPro-book ">
            <div>
              <input
                type="text"
                id="name"
                name="name"
                placeholder={t("Enter your name")}
                value={formData.name}
                onChange={handleChange}
                className={`p-3 md:px-4 text-20 bg-skyYellow placeholder:text-black placeholder:text-xl placeholder:  text-black w-full md:mt-1  border ${errors.name ? "border-red-500" : "border-gray-300"
                  } focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
              />
              {errors.name && (
                <p className="text-red-500 text-20  mb-0">{t(errors.name)}</p>
              )}
            </div>  
            <div>
              <input
                type="email"
                id="email"
                name="email"
                placeholder={t("Enter your email")}
                value={formData.email}
                onChange={handleChange}
                className={`p-3 md:px-4 text-20 placeholder:text-black placeholder:text-xl placeholder: w-full bg-skyYellow text-black  md:mt-1  border ${errors.email ? "border-red-500" : "border-gray-300"
                  }  focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
              />
              {errors.email && (
                <p className="text-red-500 text-20  mb-0">{t(errors.email)}</p>
              )}
            </div>
            <div>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                placeholder={t("Enter your phone no.")}
                onChange={handleChange}
                className="p-3 md:px-4 text-20 placeholder:text-black placeholder:text-xl  bg-skyYellow text-black w-full md:mt-1  border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
              {errors.phone && (
                <p className="text-red-500 text-20 mb-0">{t(errors.phone)}</p>
              )}
            </div>

            <div>
              <div className="relative md:mt-1 text-xl ">
                <div
                  type="button"
                  style={{ background: "#F9EFDE" }}
                  className={` capitalize relative text-black w-full md:p-4 p-[9px] md:px-4 text-20 text-left border texzt_ex ${errors.subject ? "border-red-500" : "border-gray-300"
                    }  focus:outline-none focus:ring-blue-500 focus:border-blue-500 bg-skyYellow`}
                  onClick={() =>
                    document
                      .getElementById("subject-options")
                      .classList.toggle("hidden")
                  }
                >
                  {t(formData.subject || "Subject")}
                  <LazyLoadImage
                    src={Icons.arror_down}
                    className="absolute right-6 md:top-6 top-3 subject"
                  />
                </div>
                <ul
                  id="subject-options"
                  className="absolute z-10 left-0 mt-2 max-[767px]:pl-0 w-full bg-white rounded-md shadow-lg border border-gray-300 hidden"
                >
                  <li
                    onClick={() => {
                      setFormData({
                        ...formData,
                        subject: "Chalet Reservation",
                      });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 py-2 text-20 hover:bg-gray-100 cursor-pointer"
                  >
                    {t("Chalet Reservation")}
                  </li>
                  <li
                    onClick={() => {
                      setFormData({
                        ...formData,
                        subject: "Family & Friends Celebration",
                      });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 text-20 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {t("Family & Friends Celebration")}
                  </li>
                  <li
                    onClick={() => {
                      setFormData({ ...formData, subject: "Corporate Group " });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 py-2 text-20 hover:bg-gray-100 cursor-pointer"
                  >
                    {t("Corporate Group ")}
                  </li>
                  <li
                    onClick={() => {
                      setFormData({ ...formData, subject: " Wedding" });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 py-2 text-20 hover:bg-gray-100 cursor-pointer"
                  >
                    {t(" Wedding")}
                  </li>
                  <li
                    onClick={() => {
                      setFormData({ ...formData, subject: "General Inquiry" });
                      document
                        .getElementById("subject-options")
                        .classList.add("hidden");
                    }}
                    className="px-3 py-2 text-20 hover:bg-gray-100 cursor-pointer"
                  >
                    {t("General Inquiry")}
                  </li>
                </ul>
              </div>
              {errors.subject && (
                <p className="text-red-500 text-20 mb-0">{t(errors.subject)}</p>
              )}
            </div>
          </div>
          <div>
            <textarea
              id="message"
              name="message"
              placeholder={t("message")}
              rows="4"
              value={formData.message}
              onChange={handleChange}
              className="p-3 max-[767px]:mt-2 md:px-4 text-20 placeholder:p-0 placeholder:text-black placeholder:text-xl placeholder:capitalize bg-skyYellow text-black w-full md:mt-1  border border-gray-300  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            ></textarea>
            {errors.message && (
              <p className="text-red-500 -mt-1 text-20 mb-0">
                {t(errors.message)}
              </p>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              style={{ background: "#BD8833", color: "white" }}
              className="AkagiPro-Black md:px-24 bg-skyYellow  md:py-3   mt-2 text-20  hover:text-black hover:bg-white max-[767px]:px-5   max-[767px]:py-1 Send uppercase an_button"
            >
              {t("Send")}
            </button>
          </div>
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <div className="success-popup-content">
            <LazyLoadImage src={Icons.Close} className=" m-auto w-20 mb-8" />
            <h2>{t("Email sent successfully!")}</h2>
            <button
              onClick={handleCloseSuccessPopup}
              className="AkagiPro-Black"
            >
              {t("Close")}
            </button>
          </div>
        </div>
      )}

      {showSuccessPopup && (
        <div className="success-popup">
          <div className="success-popup-content">

            <h2>{t("Email sent successfully!")}</h2>
            <button
              onClick={handleCloseSuccessPopup}
              className="AkagiPro-Black"
            >
              {t("Close")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
