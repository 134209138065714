import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
function BackgroundImage({ Depending }) {
  const { t } = useTranslation();
  return (
    <div>
      {Depending.map((item, index) => (
        <div
          className="bg-no-repeat relative flex justify-center items-center text-white py-5 Depending_img"
          style={{
            // backgroundImage: `url(${item.bgimg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            minHeight: `${item.height}`,
            backgroundPosition: "bottom ,top",
          }}
        >
          <LazyLoadImage
            src={item.bgimg}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />

          <div className="text-center container">
            <div className="flex justify-center w-full -mb-1 capitalize">
              <div className="flex items-center md:gap-8 max-[767px]:gap-3 line-des1">
                <div
                  className="md:w-16 max-[767px]:w-5 uppercase  bg-white  items-center mb-0 line-des"
                  style={{ height: "1px", marginTop: "0px" }}
                ></div>
                <h3 className="AkagiPro-Black text-64 Alfarn uppercase">
                  {t(item.Rentals)}
                </h3>
                <h3 className="AkagiPro-Black text-64 capitalize">
                  {t(item.Rentalsdd)}
                </h3>
              </div>
            </div>

            <h1
              className="text-white   text-30  AkagiPro-Black  capitalize md:mt-3"
              style={{ lineHeight: "49px" }}
              dangerouslySetInnerHTML={{ __html: t(item.name) }}
            />

            <div className="grid justify-center">
              {item.montreal ? (
                <p
                  className="text-20  AkagiPro-Black "
                  style={{
                    maxWidth: `${item.width}`,
                    textTransform: `${item.capitalize}`,
                  }}
                  dangerouslySetInnerHTML={{ __html: t(item.montreal) }}
                />
              ) : (
                <p style={{ display: "none" }} />
              )}
              <p
                className="text-20  AkagiPro-book  "
                style={{
                  maxWidth: `${item.width}`,
                  textTransform: `${item.capitalize}`,
                }}
                dangerouslySetInnerHTML={{ __html: t(item.getaways) }}
              />
            </div>
            {item.button ? (
              <button
                style={{ fontSize: "18px" }}
                className="w-52 mt-12 book_button AkagiPro-Black"
              >
                {t(item.button)}
              </button>
            ) : (
              <button style={{ display: "none" }}></button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
export default BackgroundImage;
