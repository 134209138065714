import React from "react";
import Icons from "../../Constants/Icon";
import fiddlerchalet from '../../Assets/Icons/texture-chalet.png'
import { Link } from "react-router-dom";
import BookNow from "../Button/BookNow";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from "react-lazy-load-image-component";
function GroupsEvents({ Corporategroupsd }) {
   
    const handleLinkClick = () => {

        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    };
    const { t } = useTranslation();
    return (
        <div>
            <div className="bg-lightsky md:py-16 md:pt-14 max-[767px]:py-5 md:mb-5 md:mt-12 max-[767px]:mt-7 gup_ev">
                {Corporategroupsd.map((itmes) => (

                    <div className="container">
                        <section className="text-center container  px-8">
                            <h3 className="AkagiPro-Black text-30 mb-0">{t(itmes.Corporatename)}</h3>
                            <h1 className="text-black text-48 Alfarn capitalize"  >{t(itmes.textcontant)}</h1>
                        </section>
                        <div className="md:mt-20 max-[767px]:mt-5">
                            <div className="flex justify-center ">
                                <div className="w-[55%]  max-[767px]:-ml-[11px] colleagues" >
                                    <div className="animation_img h-full">
                                        <LazyLoadImage src={itmes.img} className="img_animation" style={{ height: "100%", objectFit: "cover" }} />
                                    </div>
                                </div>
                                <div className="w-[60%]  colleagues1 md:-ml-[5%] max-[767px]:-ml-[10%] z-10 relative flex  items-center py-3">
                                    <div className="EVENTS_L">
                                        <div className="bg-white GROUPS_ev black_shoadow md:p-[35px] max-[767px]:p-[15px]" style={{

                                            backgroundImage: 'url(' + Icons.Birds + ')',
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat"
                                        }}>
                                            <div className="flex j w-full">
                                                <div className='flex items-center  md:gap-8 max-[767px]:gap-2 line-des1'>
                                                    <div className='md:w-16 max-[767px]:w-5  bg-black  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                                                    <h3 className="AkagiPro-Black  text-30 ">{t("GROUPS & EVENTS")}</h3>
                                                </div>
                                            </div>
                                            <h1 className="text-black text-48 Akagi-Pro-blacks    md:-mt-2 max-[767px]:-mt-2 " style={{ lineHeight: "0.9", textTransform: `${itmes.capitalize}` }}>{t(itmes.Corporate)}</h1>
                                            <p className="text-20 md:mt-4 AkagiPro-book">
                                                {t(itmes.fabulous)}
                                            </p>
                                            {BookNow.map((BookNow) => (<Link to='/family&friends' onClick={handleLinkClick} className='text-white'> <button className=' bg-lightyellow text-20  mt-3 book_button p_button capitalize AkagiPro-Black text-white Learn_more' >{t("Learn more")}</button></Link>))}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="flex justify-center md:mt-20 max-[767px]:mt-5">

                                <div style={{ width: "60%", marginRight: "-9%" }} className=" colleagues1 z-10 relative flex  items-center py-3">
                                    <div className="EVENTS_L left_EVENTS_L">
                                        <div className="bg-white GROUPS_ev black_shoadow   md:p-[35px] max-[767px]:p-[15px]" style={{

                                            backgroundImage: 'url(' + Icons.Birds + ')',
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat"
                                        }}>
                                            <div className="flex justify-end w-full">
                                                <div className='flex items-center  md:gap-8 max-[767px]:gap-2 line-des1'>
                                                    <div className='md:w-16 max-[767px]:w-5   bg-black  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                                                    <h3 className="AkagiPro-Black text-30 ">{t("GROUPS & EVENTS")}</h3>
                                                </div>
                                            </div>
                                            <h1 className="text-black text-48 Akagi-Pro-blacks  md:-mt-1   max-[767px]:-mt-2 text-right capitalize" style={{ lineHeight: "0.9", textTransform: `${itmes.capitalize}` }}>{t(itmes.Wedding)}</h1>

                                            <p className="text-20 md:mt-4  text-justify md:pl-6 AkagiPro-book">
                                                {t(itmes.Romantic)}
                                            </p>
                                            <div className="flex justify-end w-full">
                                                {BookNow.map((BookNow) => (<Link to={itmes.Link} className='text-white' onClick={handleLinkClick} >  <button style={{ fontSize: "18px" }} className='bg-lightyellow mt-3 book_button capitalize p_button AkagiPro-Black text-white Learn_more' >{t(itmes.Birds)}</button></Link>))}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ width: "55%" }} className=" colleagues relative max-[767px]:-right-[11px]" >
                                    <div className="animation_img h-full">
                                        <LazyLoadImage src={itmes.imgs} className="img_animation" style={{ height: "100%", objectFit: "cover" }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div >
    )
}
export default GroupsEvents;