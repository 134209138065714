import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Icons from "../../Constants/Icon";
import { useEffect, useState, useRef } from "react";
import BookNow from "../Button/BookNow";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import screenfull from 'screenfull';
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function SimpleSlider({ Sauveur }) {

    const [slidesTorows, setSlidesTorows] = useState(1);
    const [sliderReady, setSliderReady] = useState(true);
 

    const settings = {
        dots: false,
        infinite: true,
        // pauseOnHover: false,
        speed: 2000,
        slidesToShow: 1,
        autoplay: false,
        autoplaySpeed: 12000,
    };
    const { t } = useTranslation();

    const videoRef = useRef(null);



    const VideoPlayer = () => {
        const videoRef = useRef(null);
    }

    const handleFullscreen = () => {
        if (screenfull.isEnabled) {
            screenfull.request(videoRef.current);
        }
    };
    return (
        <div className="relative data_img overflow-hidden Depending_img md:h-[100vh]" >
            <div className="absolute w-full h-full flex justify-center items-center z-10 top-0">
                {Sauveur.map((item, index) => (
                    <div className='text-center z-10 text-white' >
                        <h3 class=" Alfarn text-48 "  >{t(item.name)}</h3>
                    </div>))}
            </div>
            <Slider {...settings} className='slider_left' >

                {Sauveur.map((item, index) => {
                    const isVideo = item.bgimg.endsWith('.mp4') || item.bgimg.endsWith('.webm') || item.bgimg.endsWith('.ogg');

                    return (
                        <div className="relative" key={index}>
                            {isVideo ? (
                                <video ref={videoRef}
                                    autoplay="autoplay"
                                    loop
                                    muted
                                    playsInline
                                    controls={false}


                                    className="data_img h-full w-full object-cover Depending_img slider_imgs md:h-[100vh]" >
                                    <source src={item.bgimg} type="video/mp4" />
                                </video>
                            ) : (
                                <LazyLoadImage
                                    src={item.bgimg}
                                    style={{ objectPosition: `${item.Right}` }}
                                    className="data_img h-full w-full object-cover Depending_img slider_imgs md:h-[100vh]"
                                    alt="Media"
                                />
                            )}
                            <div className="black_s"></div>
                            <div>
                                {/* Add any additional content here */}
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>

    );
}
