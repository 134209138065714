import React, { useState, useEffect } from 'react';
import GroupsEvents from "../../../../component/Groups & Events/GroupsEvents";
import Drinkingbg from "../../../../component/backgroundtext/resortsbg"
import Icons from "../../../../Constants/Icon";
import Reservation from "../../../../component/contactus/Reservation";
import Bookfooter from "../../../../component/footer/Bookfooter";
import BookNow from '../../../../component/Button/BookNow';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Contact from "../../../../component/contactus/Contact";
import { LazyLoadImage } from "react-lazy-load-image-component";

const reservation = [{
    title: "Group reservations",
    planning: "Start planning your next family and friends gathering today. We invite you to contact us at ",
    complete: " or complete the contact form below. See you soon!"
}]
const Corporategroups = [{
    Wedding: "Weddings ",
    Corporate: "Corporate Groups",
    fabulous: "Host your next business meeting, training session, or team building activity in a fabulous destination in the Laurentians. Our chalets offer an ideal environment that fosters team spirit, creativity, and collaboration.",
    Birds: "Learn more",
    Romantic: "Celebrate your dream wedding in a uniquely charming and romantic setting. We offer you an extraordinary and customized experience. We’ll help you create memories that will be cherished for a lifetime.",
    img: Icons.CorporateGroups,
    imgs: Icons.wedding123,
    Link: "/weddings",
    // Corporatename: "GROUPS & EVENTS – Corporate",
    textcontant: "",


}]
const contactdata = [{
    name:"Group reservations",
    planning:"Start planning your next family and friends gathering today. We invite you to contact us at",
    complete:"or complete the contact form below. See you soon!"
}]
const restaurants = [
    {
        title: "Our Chalets ",
        titlename: "Our luxurious chalets with floor-to-ceiling windows provide breathtaking views of nature and are perfect for accommodating 6-18 people.",
        Image: Icons.Family5,
        chaletsmodels: "DISCOVER OUR CHALETS",
        Link: "/discover_chalets",


    },
    {
        title: "Activities for Everyone",
        titlename: "Fiddler Lake Resort has something for everyone, either on-site or nearby. Activities include canoeing, kayaking, tennis, hiking, mountain biking, indoor heated pool, pool table, snowshoeing, ice skating, downhill skiing, yoga, fishing, rock climbing, horseback riding, zip-lining, and much more!",
        Image: Icons.Family6,
        chaletsmodels: "DISCOVER THE FIDDLER LAKE EXPERIENCE",
        Link: "/experience",
        capitalize: "none",
    },
    {
        title: "Kid-friendly Fun",
        titlename: "Children will never be bored at Fiddler Lake Resort! When the sun is shining, children can have a blast at the outdoor playground, basketball court, tennis court, pickleball courts, badminton court, horseshoe pits and beanbag toss area, skating rink, and much more. If the weather doesn’t cooperate, simply head indoors to the heated pool.",
        Image: Icons.Family7,
        chaletsmodels: "EXPLORE OUR RESORT ACTIVITIES",
        Link: "/resortactivities",
        capitalize: "unset"


    },
    {
        title: "Baby gear rentals",
        titlename: "There’s no need to worry about keeping babies or toddlers safe during your stay. Our selection of baby gear rentals includes highchairs, playpens, safety gates for stairs, and more, delivered and installed upon request.",
        Image: Icons.Family8,
        chaletsmodels: "Explore our concierge services",
        Link: "/resortservices"
    },

]


function Family({ setLoading }) {

    const [ResponsiveHeight, setResponsiveHeight] = useState(
        window.innerWidth > 768 ? "100vh" : "30vh"
    );

    useEffect(() => {
        const handleResize = () => {
            const newHeight = window.innerWidth > 768 ? "100vh" : "30vh";
            setResponsiveHeight(newHeight);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const Depending = [{
        Rentals: "FAMILY & FRIENDS",
        bgimg: Icons.Family,
        height: ResponsiveHeight,

    }]
    const Sauveur = [{
        name: "FAMILY & FRIENDS",
        bgimg: Icons.Family,
        height: "70vh",
        width: "1200px",
    }
    ]
  
    const handleLinkClick = () => {

    };
    const sectionimg = {
        backgroundImage: 'url(' + Icons.fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minheight: "882px",

    };

    const handlesLinkClick = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000);
        const div = document.getElementById("contant");
        if (div) {
            window.scrollTo({
                top: div.offsetTop - 100,
                behavior: 'smooth'
            })
        }
    }
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const urls = {
        en: "https://company-15146508.staycation.igms.com/?lang=en",
        fr: "https://company-15146508.staycation.igms.com/?lang=fr"
    };
    const currentUrl = urls[currentLanguageCode] || urls.en;
    return (
        <div>
            <div className='family_fillte family_bg'>
                <Drinkingbg Depending={Depending} />
            </div>
            <section className='bg-skyYellow flex items-center  justify-center relative md:mt-20 max-[767px]:mt-10 ' >
                <div className='container px-8 '>
                    <div className='grid grid-cols-2 max-[352px]:grid-cols-1 md:gap-14 max-[767px]:gap-5 h-full'>
                        <div className='flex justify-left items-center max-[767px]:py-5 md:py-12' >
                            <div >
                                <h1 className=" text-64 Alfarn capitalize" >{t("Your personalized event")}</h1>
                                <p className="text-20 text-justify AkagiPro-book md:mt-8">
                                    {t("Whether you’re celebrating a milestone birthday or hosting a family BBQ, our dedicated team is here to assist you every step of the way, from choosing the perfect chalet, to planning special activities and an itinerary for your stay. We offer a wide array of options to personalize it with your own style.")}
                                </p>
                                <button onClick={handlesLinkClick} className=' book_button  AkagiPro-Black text-white  md:mt-6 max-[767px]:mt-3 uppercase' style={{ paddingLeft: "20px", paddingRight: "20px" }} > {t("Plan your gathering now")}</button>
                            </div>
                        </div>
                        <div className='relative md:-bottom-3'>
                            <div className='grid grid-cols-2 md:gap-3 max-[767px]:gap-1 max-[767px]:h-full h-full max-[352px]:h-[247px] '>
                                <div className='bg-black relative  max-[767px]:h-[100%] h-full p-2 md:-mt-12 max-[767px]:-mt-4  animation_img'>
                                    <LazyLoadImage className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.personalizedevents1} />

                                </div>
                                <div className='bg-black relative h-full  p-2 max-[767px]:h-[100%] md:-mt-1 max-[767px]:-bottom-2 animation_img'>
                                    <LazyLoadImage className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.personalizedevents2} />

                                </div>
                                <div className='bg-black relative h-full  p-2 max-[767px]:h-[100%] md:-mt-11 max-[767px]:-mt-3 animation_img'>
                                    <LazyLoadImage className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.personalizedevents3} />

                                </div>
                                <div className='bg-black relative h-full  max-[767px]:h-[100%] p-2 md:-mb-5 max-[767px]:-bottom-3 animation_img'>
                                    <LazyLoadImage className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.personalizedevents4} />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <section className='container relative px-8  '>
                <div className='grid  md:grid-cols-2 gap-16 md:mt-24 md:mb-20  mb-10 mt-20 md:p-12 max-[767px]:p-8  max-[767px]:pl-0 md:pl-6 py-0'>
                    {restaurants.map((item, index) => (


                        <div className='bg-lightyellow relative pb-16 '>
                            <div className='w-full relative -top-7 -right-7 animation_img'>
                                <LazyLoadImage src={item.Image} className='w-full relative img_animation' /></div>
                            <div className='px-7 text-white'>
                                <h3 className="AkagiPro-Black text-20 " >  {t(item.name)}</h3>
                                <p className='text-48 -mt-3 mb-2 AkagiPro-Black ' style={{ textTransform: `${item.capitalize}`, lineHeight: "47px" }} >{t(item.title)}</p>
                                <p className='text-20 AkagiPro-book'>
                                    {t(item.titlename)}</p>
                                {item.chaletsmodels ? (
                                    <div>
                                        {BookNow.map((BookNow) => (<Link to={item.Link} onClick={handleLinkClick} className='text-white'> <h3 className="AkagiPro-Black text-20 uppercase flex items-baseline gap-2 absolute bottom-5 cursor-pointer pr-5" >{t(item.chaletsmodels)} <LazyLoadImage src={Icons.whitetir} className='max-[767px]:w-[31px] max-[352px]:' /></h3></Link>))}</div>
                                ) : (<h3 style={{ display: "none" }}></h3>)}
                            </div>

                        </div>
                    ))} </div>
            </section>

            <GroupsEvents Corporategroups={Corporategroups} />
            <Link to={currentUrl}>
                <div className="bg-lightyellow  button_yellow  text-white py-12 max-[767px]:my-7 md:my-12 " style={sectionimg}>
                    <div className="container">
                        {/* {BookNow.map((BookNow) => (<Link to={BookNow.Plan}></Link>))} */}

                        <div className="text-48 text-center  Alfarn">{t("PLAN YOUR GETAWAY NOW")}</div>
                    </div>
                </div>
            </Link>
            <div className='md:mb-14 max-[767px]:mb-7' id='contant' >
                {/* <Reservation reservation={reservation} /> */}
                <Contact setLoading={setLoading} contactdatanew={contactdata} />
            </div>
            <Bookfooter />

        </div>
    )
}
export default Family;