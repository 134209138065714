import React from "react";
import BackgroundImage from "../../../component/backgroundtext/backgroundimg";
import Icons from "../../../Constants/Icon";
import ContactForm from "../../../component/contactus/Contact";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import BookNow from "../../../component/Button/BookNow";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Sauveur = [{
    name: "ABOUT Us",
    height: "70vh",
    bgimg: Icons.About

}
]
const Holidaydata = [{
    birthdayimg: Icons.About2,
    familytype: "discover our chalets",
    link: "/discover",
    name: "Luxury chalets",
    titles: "Escape to one of our luxurious chalets, each boasting floor-to-ceiling windows, a stone fireplace, and an outdoor patio equipped with a BBQ. Our models range in size from three to six rooms, and depending on the chalet chosen, additional features may include a three- or four-season veranda, private hot tub, private sauna, pool table, or even a private pool."
},
{
    birthdayimg: Icons.About3,
    familytype: "Discover the Fiddler Lake experience",
    link: "/experience",
    name: "Exclusive activities",
    titles: "Take in the natural beauty of the region with a peaceful canoe or kayak ride on the 2.8 km<sup>2</sup> lake or simply explore the acres of lush forest. Have fun in our Recreation Centre or hit some balls on the tennis courts. If relaxation is more your style, unwind in our heated indoor pool, available year-round, or our heated outdoor pool, available during the summer. Our wide range of activities is sure to make your stay unforgettable."
}
    ,
{
    birthdayimg: Icons.About4,
    familytype: "Explore the region’s activities and restaurants",
    name: "The Saint-Sauveur Valley",
    link: "/restaurants",
    titles: "When you stay at Fiddler Lake Resort, you’re only minutes away from a vast array of outdoor winter activities, including downhill skiing on 149 runs, 89 of which are available for night skiing, or cross-country skiing on 150 km of groomed trails. For summer fun, enjoy golfing, rafting, kayaking, rock climbing, horseback riding, and so much more."
}
]
function About({ setLoading }) {
    const sectionimg = {
        backgroundImage: 'url(' + Icons.fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minheight: "882px",

    };
    const handleLinkClick = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);

    };
    const { t } = useTranslation();
    return (<div className="About_d">
        <BackgroundImage Sauveur={Sauveur} />

        <section className="md:my-14  max-[767px]:my-5 ">
            <div className="container">
                <div className="flex ">
                    <div className="relative flex items-center -ml-3 md:w-[50%] z-10  max-[767px]:w-[70%]" >
                        <div className="bg-skyYellow md:py-10 md:px-12 max-[767px]:p-4 max-[767px]:my-4 lg:py-16" style={{ backgroundImage: 'url(' + Icons.groups5 + ')', }}>
                            <h2 className="text-32 Akagi-Pro-blacks md:mb-6 uppercase "  ><div dangerouslySetInnerHTML={{ __html: t("Escape to a private resort on  <br /> Fiddler Lake") }} /></h2>
                            <p className="text-20 AkagiPro-book text-justify " > {t("Whether you’re looking for a getaway with family or friends, a breathtaking wedding venue, or a unique location for a corporate event, Fiddler Lake Resort offers you more than 50 magnificent chalets to choose from.")}</p>
                            <p className="text-20 AkagiPro-book text-justify max-[767px]:mt-4" >{t("Nestled across 100 acres of land in the Saint-Sauveur Valley, our charming and rustic chalets are sure to make your experience memorable.")}</p>
                        </div>
                    </div>
                    <div className="md:-ml-20 max-[767px]:-ml-5 md:w-[57%] max-[767px]:-mr-[12px] max-[767px]:w-[50%] " ><div className="max-[767px]:w-full max-[767px]:h-full  animation_img" style={{ zIndex: "-2" }}><LazyLoadImage src={Icons.About1} className="max-[767px]:w-full max-[767px]:h-full object-cover img_animation" /></div></div>

                </div>

            </div>
        </section>
        <section className="bg-lightyellow md:py-20 max-[767px]:pb-12 max-[767px]:py-3" style={{ backgroundImage: 'url(' + Icons.groups5 + ')', backgroundSize: "100%" }}>
            <div className="container">
                {Holidaydata.map((items) => (
                    <div >
                        <div> </div>
                        {items.familytype == "discover our chalets" && (
                            <div className="grid md:gap-4 max-[767px]:gap-2 grid-cols-2">
                                <div className="max-[767px]:pt-5 max-[767px]:pb-5">
                                    <div className="animation_img max-[767px]:h-full max-[767px]:w-full -mr-20 z-10">
                                        <LazyLoadImage src={items.birthdayimg} className="max-[767px]:h-full relative md:w-full  max-[767px]:w-full object-cover img_animation" />  </div>
                                </div>
                                <div className="border-l-2  border-white md:mt-20 flex justify-start items-center" >
                                    <div className="bg-white md:-mt-20 max-[767px]:mt-8 max-[767px]:mb-12   relative  black_shoadow md:p-8 max-[767px]:p-4 max-[767px]:pb-6 md:pb-16" style={{ maxWidth: "569px" }}>
                                        <h2 className="text-32 Akagi-Pro-blacks capitalize">  {t(items.name)}</h2>
                                        <p className="text-20 AkagiPro-book text-justify text-gray">{t(items.titles)}</p>
                                        <Link to={items.link} onClick={handleLinkClick} >  <button className="bg-black uppercase py-2 black_shoadow an_button max-[767px]:px-3 md:px-8 absolute -bottom-5 left-0  text-white text-20 AkagiPro-Black ">
                                            {t(items.familytype)}</button></Link>
                                    </div>
                                </div>

                            </div>

                        )}
                        {items.familytype == "Discover the Fiddler Lake experience" && (
                            <div className="grid md:gap-4 max-[767px]:gap-2 grid-cols-2">
                                <div className="flex justify-end items-center max-[767px]:py-8  max-[767px]:pb-12">
                                    <div className="bg-white  relative  black_shoadow md:p-8 max-[767px]:p-4 max-[767px]:pb-10 max-[767px]:-mr-[10px] md:-mr-[16px]" style={{ maxWidth: "569px" }}>
                                        <h2 className="text-right text-32 Akagi-Pro-blacks  md:mt-6 max-[767px]:mt-2 capitalize ">  {t(items.name)}</h2>
                                        <p className=" text-justify text-20 text-gray AkagiPro-book" dangerouslySetInnerHTML={{ __html: t(items.titles) }} />
                                        <Link to={items.link} onClick={handleLinkClick} >  <button className="bg-black py-2 black_shoadow uppercase  an_button  max-[767px]:px-3 md:px-8  absolute -bottom-5 right-0  text-white text-20 AkagiPro-Black ">
                                            {t(items.familytype)}</button></Link>
                                    </div>
                                </div>
                                <div className="border-l-2 border-white md:pl-5 pl-2 max-[767px]:py-5">
                                    <div className="max-[767px]:h-full max-[767px]:h-full  object-cover animation_img">
                                        <LazyLoadImage src={items.birthdayimg} className="max-[767px]:h-full max-[767px]:h-full  object-cover img_animation" />
                                    </div>
                                </div>

                            </div>

                        )}
                        {items.familytype == "Explore the region’s activities and restaurants" && (
                            <div className="grid md:gap-4 max-[767px]:gap-2 grid-cols-2">
                                <div className="max-[767px]:pt-5 max-[767px]:pb-5">
                                    <div className=" animation_img max-[767px]:h-full max-[767px]:w-full object-cover">
                                        <LazyLoadImage src={items.birthdayimg} className="max-[767px]:h-full max-[767px]:w-full object-cover img_animation" /></div>
                                </div>
                                <div className="border-l-2 md:pt-16  border-white md:mt-0 flex justify-start items-center " >
                                    <div className="bg-white md:-mt-20    max-[767px]:mt-8 max-[767px]:mb-12    relative  black_shoadow md:p-8 max-[767px]:p-4 max-[767px]:pb-8 md:pb-16 max-[386px]:pb-12" style={{ maxWidth: "569px" }}>
                                        <h2 className="text-32 Akagi-Pro-blacks ">  {t(items.name)}</h2>
                                        <p className="text-20 AkagiPro-book text-justify text-gray ">{t(items.titles)}</p>
                                        <Link to={items.link} onClick={handleLinkClick} >  <button className="bg-black uppercase an_button py-2 black_shoadow   max-[767px]:px-3 md:px-8 absolute -bottom-5 left-0  text-white text-20 AkagiPro-Black ">
                                            {t(items.familytype)}</button></Link>
                                    </div>
                                </div>

                            </div>

                        )}

                    </div>
                ))}
            </div>
        </section>
        <Link to='http://fiddlerlakeresort.com/reservations&sa=D&source=docs&ust=1712658983427156&usg=AOvVaw0TeStyze-nlLFZklrWCbZYhttp://fiddlerlakeresort.com/reservations'>
            <div className="bg-skyYellow  button_yellow  text-black md:py-12 max-[767px]:py-5  md:my-12 max-[767px]:my-5" style={sectionimg}>
                <div className="container">
                    {/* {BookNow.map((BookNow) => (<Link to={BookNow.Plan}></Link>))} */}

                    <div className="text-48 text-center  Alfarn">{t("PLAN YOUR GETAWAY NOW")}</div>
                </div>
            </div></Link>
        <ContactForm setLoading={setLoading}/>

    </div>)
}

export default About;